import React, { useContext, useEffect, useState } from "react";
import ToggleButton from "./ToggleButton";
import ModeButton from "./ModeButton";
import DeviceSetting from "./DeviceSetting";
import SettingButton from "./SettingButton";
import { TbCheck } from "react-icons/tb";
import * as api from "apis/FusionAPI";
import { ColorContext, DeviceContext } from "App";
import { usePerms } from "hooks/usePerms";

export default function AwareFloodDeviceSettings({
  setShowDeviceSettings,
  selectedDevice,
  nodeList,
  mobile = false,
}) {
  const perms = usePerms();
  const deviceContext = useContext(DeviceContext);

  const device = nodeList[selectedDevice];

  const theme = useContext(ColorContext);

  const [commandSent, setCommandSent] = useState(false);

  const [deviceSettings, setDeviceSettings] = useState({
    node_id: device.node_id,
    uuid: device?.uuid,
    imei: device?.IMEI,

    depthDet1: device?.device_settings.depthDet1,
    depthDet2: device?.device_settings.depthDet2,
    depthDet3: device?.device_settings.depthDet3,
    depthHoldHours: device?.device_settings.depthHoldHours,
    ffTheshold: device?.device_settings.ffTheshold ?? 0, // Use fallback value if null
    dropThresh: device?.device_settings.dropThresh,

    mode: device?.device_settings.mode,
    samp: device?.device_settings.samp,

    dDetImgEnable: device.device_settings.dDetImgEnable,
    ffiImgEnable: device.device_settings.ffiImgEnable ?? 0, // Use fallback value if null
    dRateImgEnable: device.device_settings.dRateImgEnable,
    hResImgEnable: device.device_settings.hResImgEnable,

    // water_level_adj: device?.device_settings.water_level_adj, // this is the fixed value for once water_level_adk gets added to the settings
    water_level_adj: device?.device_settings.water_level_adj,

    // saltwater: 0,
    externalDevice: !!device?.device_settings.externalDevice,
    volConstant: device?.device_settings.volConstant,
    calibration: device?.device_settings.calibration,
    resetCalibration: false,
    triggeredImagingHoldOff: 1,
    imgHoldStart: device?.device_settings.imgHoldStart,
    imgHoldEnd: device?.device_settings.imgHoldEnd,

    imageRequest: false,
    gpsSync: false,

    ipAddress: device?.device_settings.ipAddress,
    port: device?.device_settings.port,

    reboot: false,
  });

  const [copyId, setCopyId] = useState(null);

  const [ipParts, setIpParts] = useState(
    deviceSettings.ipAddress ? deviceSettings.ipAddress.split(".") : []
  );

  const handleIpPartChange = (partIndex, newPart) => {
    const newIpParts = [...ipParts];
    newIpParts[partIndex] = newPart;
    setIpParts(newIpParts);
    setDeviceSettings({
      ...deviceSettings,
      ipAddress: newIpParts.join("."),
    });
  };

  const formatTime = (hour) => {
    if (hour === 0) {
      return "12AM";
    } else if (hour < 12) {
      return `${hour}AM`;
    } else if (hour === 12) {
      return "12PM";
    } else {
      return `${hour - 12}PM`;
    }
  };

  const parseTime = (time) => {
    if (time === "12AM") {
      return 0;
    } else if (time.endsWith("AM")) {
      return parseInt(time, 10);
    } else if (time === "12PM") {
      return 12;
    } else {
      return parseInt(time, 10) + 12;
    }
  };

  const handleInputChange = (field, value) => {
    setDeviceSettings((prevSettings) => ({
      ...prevSettings,
      [field]: value,
    }));
  };

  const reportingModesOptions = {
    0: "Auto",
    1: "Fast",
    2: "Slow",
  };
  const samplingModesOptions = { 0: "30 seconds", 1: "60 seconds" };

  const imagingModes = [
    {
      displayName: "Depth Detection Imaging",
      settingName: "dDetImgEnable",
    },
    { displayName: "Rise Rate Imaging", settingName: "ffiImgEnable" },
    { displayName: "Drop Rate Imaging", settingName: "dRateImgEnable" },
    {
      displayName: "High Resolution Imaging",
      settingName: "hResImgEnable",
    },
  ];

  const getChangedFields = (original, updated) => {
    let changes = {};

    for (let key in updated) {
      if (key === "node_id" || key === "imei" || key === "uuid") {
        // Always include node_id, IMEI, and UUID
        changes[key] = updated[key];
        continue;
      }

      if (
        key === "saltwater" ||
        key === "resetCalibration" ||
        key === "triggeredImagingHoldOff" ||
        key === "imageRequest" ||
        key === "gpsSync" ||
        key === "reboot"
      ) {
        // Always ignore
        continue;
      }

      if (original[key] === null && updated[key] === 0) {
        // Treat null in original and 0 in updated as unchanged for specific fields
        if (key === "ffTheshold" || key === "ffiImgEnable") {
          continue;
        }
      }

      if (updated[key] !== original[key]) {
        changes[key] = updated[key];
      }
    }

    return changes;
  };

  const sendCommand = async () => {
    let changedFields = getChangedFields(
      device.device_settings,
      deviceSettings
    );

    try {
      await api.changeAwareDeviceSettings(changedFields);

      setCommandSent(true);

      setTimeout(() => {
        setCommandSent(false);
        deviceContext.getDevices();
      }, 5000);

      // setShowDeviceSettings(false);
    } catch (error) {
      console.error(error.message);
    }
  };

  if (mobile) {
    return (
      <div>
        This feature is currently not implemented. Please manage AWARE devices
        from the desktop website.
      </div>
    );
  }

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
      }}
    >
      <div
        style={{
          backgroundColor: theme.base,
          padding: 30,
          maxHeight: "calc(100% - 60px)",
          overflowY: "auto",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              flex: 100,
            }}
          >
            <h1>
              {(device.device_type !== "Manual Road Closure"
                ? device.static_id + " "
                : "") + device.alias}{" "}
              Device Settings ({device.node_id}){" "}
            </h1>
            {perms.testPermission(
              [8, 9, 10, 11, 12, 13],
              ["Create-EditAWAREFloodConnections"]
            ) && (
              <>
                <label style={{ marginLeft: 50 }}>Copy settings from: </label>
                <select
                  value={copyId}
                  onChange={(e) => {
                    const selectedOption = nodeList.find(
                      (device) => device.node_id === e.target.value
                    ).device_settings;

                    setCopyId(e.target.value);

                    setDeviceSettings({
                      ...deviceSettings,
                      depthDet1: selectedOption.depthDet1,
                      depthDet2: selectedOption.depthDet2,
                      depthDet3: selectedOption.depthDet3,
                      depthHoldHours: selectedOption.depthHoldHours,
                      ffTheshold: selectedOption.ffTheshold,
                      dropThresh: selectedOption.dropThresh,
                      mode: selectedOption.mode,
                      samp: selectedOption.samp,
                      dDetImgEnable: selectedOption.dDetImgEnable,
                      ffiImgEnable: selectedOption.ffiImgEnable ?? 0, // Use fallback value if null
                      dRateImgEnable: selectedOption.dRateImgEnable,
                      hResImgEnable: selectedOption.hResImgEnable,
                    });
                  }}
                  style={{
                    backgroundColor: theme.primaryHighlight,
                    padding: 5,
                    borderRadius: 5,
                    marginLeft: 10,
                  }}
                >
                  {nodeList.map((device) => {
                    if (device.device_type === "Flood")
                      return (
                        <option key={device.node_id} value={device.node_id}>
                          {(device.device_type !== "Manual Road Closure"
                            ? device.static_id + " "
                            : "") + device.alias}
                        </option>
                      );
                  })}
                </select>
              </>
            )}
          </div>

          <div
            style={{
              display: "flex",
              flex: 1,
              cursor: "pointer",
              marginLeft: 10,
              justifyContent: "flex-end",
            }}
            onClick={() => setShowDeviceSettings(false)}
          >
            <h2>X</h2>
          </div>
        </div>

        <div style={{ display: "flex", flexDirection: "row", marginTop: 30 }}>
          <div style={{ borderRight: "1px solid grey", width: 500 }}>
            <div style={{ marginTop: 15 }}>
              <h4>Threshold Settings</h4>
              <DeviceSetting
                settingName="Depth Detection Threshold 1"
                settingDataName="depthDet1"
                setting={deviceSettings.depthDet1}
                metric={"inches"}
                min={1}
                max={254}
                turnOffAt={255}
                deviceSettings={deviceSettings}
                handleInputChange={handleInputChange}
                device={device}
                type={"int"}
                enabled={perms.testPermission(
                  [8, 9, 10, 11, 12, 13],
                  ["Create-EditAWAREFloodConnections"]
                )}
              />
              <DeviceSetting
                settingName="Depth Detection Threshold 2"
                settingDataName="depthDet2"
                setting={deviceSettings.depthDet2}
                metric={"inches"}
                min={1}
                max={254}
                turnOffAt={255}
                deviceSettings={deviceSettings}
                handleInputChange={handleInputChange}
                device={device}
                type={"int"}
                enabled={perms.testPermission(
                  [8, 9, 10, 11, 12, 13],
                  ["Create-EditAWAREFloodConnections"]
                )}
              />
              <DeviceSetting
                settingName="Depth Detection Threshold 3"
                settingDataName="depthDet3"
                setting={deviceSettings.depthDet3}
                metric={"inches"}
                min={1}
                max={254}
                turnOffAt={255}
                deviceSettings={deviceSettings}
                handleInputChange={handleInputChange}
                device={device}
                type={"int"}
                enabled={perms.testPermission(
                  [8, 9, 10, 11, 12, 13],
                  ["Create-EditAWAREFloodConnections"]
                )}
              />
              <DeviceSetting
                settingName="Depth Detection Holdoff"
                settingDataName="depthHoldHours"
                setting={deviceSettings.depthHoldHours}
                metric={"hours"}
                min={0}
                max={24}
                turnOffAt={0}
                deviceSettings={deviceSettings}
                handleInputChange={handleInputChange}
                device={device}
                enabled={perms.testPermission(
                  [8, 9, 10, 11, 12, 13],
                  ["Create-EditAWAREFloodConnections"]
                )}
              />
              <DeviceSetting
                settingName="Rise Rate Threshold"
                settingDataName="ffTheshold"
                setting={deviceSettings.ffTheshold}
                metric={"in/min"}
                min={0}
                max={2.54}
                turnOffAt={null}
                step={0.01}
                deviceSettings={deviceSettings}
                handleInputChange={handleInputChange}
                device={device}
                type={"float"}
                enabled={perms.testPermission(
                  [8, 9, 10, 11, 12, 13],
                  ["Create-EditAWAREFloodConnections"]
                )}
              />
              <DeviceSetting
                settingName="Drop Rate Threshold"
                settingDataName="dropThresh"
                setting={deviceSettings.dropThresh}
                metric={"in/min"}
                min={0}
                max={2.54}
                turnOffAt={2.55}
                step={0.01}
                deviceSettings={deviceSettings}
                handleInputChange={handleInputChange}
                device={device}
                type={"float"}
                enabled={perms.testPermission(
                  [8, 9, 10, 11, 12, 13],
                  ["Create-EditAWAREFloodConnections"]
                )}
              />

              <h4 style={{ marginTop: 15 }}>Modes</h4>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 5,
                }}
              >
                <div
                  style={{ width: 10, marginRight: 15, alignItems: "center" }}
                >
                  {deviceSettings.mode !== device.device_settings.mode && (
                    <TbCheck color="green" size={20} />
                  )}
                </div>
                <label>Reporting Modes:</label>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    backgroundColor: theme.primaryShadow,
                    borderRadius: 50,
                    marginLeft: 5,
                    marginBottom: 10,
                  }}
                >
                  {Object.entries(reportingModesOptions).map(
                    ([mode, modeName]) => (
                      <ModeButton
                        key={mode}
                        mode={mode}
                        modeName={modeName}
                        keyName="mode"
                        setMode={() => {
                          setDeviceSettings({
                            ...deviceSettings,
                            mode: parseInt(mode),
                          });
                        }}
                        deviceSettings={deviceSettings}
                        enabled={perms.testPermission(
                          [8, 9, 10, 11, 12, 13],
                          ["Create-EditAWAREFloodConnections"]
                        )}
                      />
                    )
                  )}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 5,
                }}
              >
                <div
                  style={{ width: 10, marginRight: 15, alignItems: "center" }}
                >
                  {deviceSettings.samp !== device.device_settings.samp && (
                    <TbCheck color="green" size={20} />
                  )}
                </div>
                <label>Sampling Modes:</label>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    backgroundColor: theme.primaryShadow,
                    borderRadius: 50,
                    marginLeft: 5,
                  }}
                >
                  {Object.entries(samplingModesOptions).map(
                    ([mode, modeName]) => (
                      <ModeButton
                        key={mode}
                        mode={mode}
                        modeName={modeName}
                        keyName="samp"
                        setMode={() => {
                          setDeviceSettings({
                            ...deviceSettings,
                            samp: parseInt(mode),
                          });
                        }}
                        deviceSettings={deviceSettings}
                        enabled={perms.testPermission(
                          [8, 9, 10, 11, 12, 13],
                          ["Create-EditAWAREFloodConnections"]
                        )}
                      />
                    )
                  )}
                </div>
              </div>
            </div>

            <div>
              <h4 style={{ marginTop: 15 }}>Imaging Modes</h4>
              {imagingModes.map(({ displayName, settingName }) => (
                <SettingButton
                  key={settingName}
                  displayName={displayName}
                  settingName={settingName}
                  deviceSettings={deviceSettings}
                  setDeviceSettings={setDeviceSettings}
                  device={device}
                  enabled={perms.testPermission(
                    [8, 9, 10, 11, 12, 13],
                    ["Create-EditAWAREFloodConnections"]
                  )}
                />
              ))}
            </div>
          </div>

          <div style={{ marginLeft: 30 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: 15,
              }}
            >
              <div style={{ width: 10, marginRight: 15, alignItems: "center" }}>
                {/* COMMENT THE TbCheck IN ONCE FIXED */}
                {deviceSettings.externalDevice !==
                  !!device.device_settings.externalDevice && (
                  <TbCheck color="green" size={20} />
                )}
              </div>
              <label>External Device:</label>
              <div
                style={{
                  marginLeft: 10,
                }}
              >
                <ToggleButton
                  isOff={!deviceSettings.externalDevice}
                  onClick={() => {
                    setDeviceSettings({
                      ...deviceSettings,
                      externalDevice: !deviceSettings.externalDevice,
                    });
                  }}
                  enabled={perms.testPermission(
                    [8, 9, 10, 11, 12, 13],
                    ["Create-EditAWAREFloodConnections"]
                  )}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: 15,
              }}
            >
              <div style={{ width: 10, marginRight: 15, alignItems: "center" }}>
                {deviceSettings?.water_level_adj !==
                  device?.device_settings.water_level_adj && (
                  <TbCheck color="green" size={20} />
                )}
              </div>
              <label>Water Level Adj.:</label>
              <input
                disabled={
                  !perms.testPermission(
                    [8, 9, 10, 11, 12, 13],
                    ["Create-EditAWAREFloodConnections"]
                  )
                }
                type="number"
                value={deviceSettings?.water_level_adj}
                onChange={(e) => {
                  setDeviceSettings({
                    ...deviceSettings,
                    water_level_adj: e.target.value,
                  });
                }}
                style={{
                  marginLeft: 5,
                  backgroundColor: theme.primaryHighlight,
                  borderRadius: 5,
                  width: 50,
                  textAlign: "center",
                }}
              />
              <span>Value (in.)</span>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: 15,
              }}
            >
              <div style={{ width: 10, marginRight: 15, alignItems: "center" }}>
                {deviceSettings.calibration !==
                  device?.device_settings.calibration && (
                  <TbCheck color="green" size={20} />
                )}
              </div>
              <label>Calibration Offset:</label>
              <input
                disabled={
                  !perms.testPermission(
                    [8, 9, 10, 11, 12, 13],
                    ["Create-EditAWAREFloodConnections"]
                  )
                }
                type="number"
                value={deviceSettings.calibration}
                onChange={(e) => {
                  setDeviceSettings({
                    ...deviceSettings,
                    calibration: e.target.value,
                  });
                }}
                style={{
                  marginLeft: 5,
                  backgroundColor: theme.primaryHighlight,
                  borderRadius: 5,
                  width: 50,
                  textAlign: "center",
                }}
              />
              <span>Value (in.)</span>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: 15,
                marginLeft: 150,
              }}
            >
              <input
                disabled={
                  !perms.testPermission(
                    [8, 9, 10, 11, 12, 13],
                    ["Create-EditAWAREFloodConnections"]
                  )
                }
                type="checkbox"
                style={{ marginLeft: 5, padding: 10, transform: "scale(1.5)" }}
                checked={deviceSettings.resetCalibration}
                onChange={(e) => {
                  const value = e.target.checked;
                  setDeviceSettings((prevSettings) => ({
                    ...prevSettings,
                    resetCalibration: value,
                  }));
                }}
              />
              <label style={{ marginLeft: 10 }}>Reset Calibration</label>
            </div>

            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 5,
                  marginTop: 15,
                }}
              >
                <SettingButton
                  displayName={"Triggered Imaging Holdoff"}
                  settingName={"triggeredImagingHoldOff"}
                  displayCheckmark={false}
                  deviceSettings={deviceSettings}
                  setDeviceSettings={setDeviceSettings}
                  device={device}
                  enabled={perms.testPermission(
                    [8, 9, 10, 11, 12, 13],
                    ["Create-EditAWAREFloodConnections"]
                  )}
                />
              </div>
            </div>
            <div
              style={{
                marginLeft: 200,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 5,
                }}
              >
                <div
                  style={{ width: 10, marginRight: 15, alignItems: "center" }}
                >
                  {deviceSettings.imgHoldStart !==
                    device.device_settings.imgHoldStart && (
                    <TbCheck color="green" size={20} />
                  )}
                </div>
                <select
                  disabled={
                    !perms.testPermission(
                      [8, 9, 10, 11, 12, 13],
                      ["Create-EditAWAREFloodConnections"]
                    ) || !deviceSettings.triggeredImagingHoldOff
                  }
                  value={formatTime(deviceSettings.imgHoldStart)}
                  onChange={(e) => {
                    setDeviceSettings({
                      ...deviceSettings,
                      imgHoldStart: parseTime(e.target.value),
                    });
                  }}
                  style={{
                    marginLeft: 5,
                    backgroundColor: theme.primaryHighlight,
                    borderRadius: 5,
                    width: 100,
                    textAlign: "center",
                    padding: 5,
                  }}
                >
                  {Array.from({ length: 24 }, (_, i) => formatTime(i)).map(
                    (time, i) => (
                      <option key={i} value={time}>
                        {time}
                      </option>
                    )
                  )}
                </select>
                <label style={{ marginLeft: 15 }}>Hour Start</label>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div
                  style={{ width: 10, marginRight: 15, alignItems: "center" }}
                >
                  {deviceSettings.imgHoldEnd !==
                    device.device_settings.imgHoldEnd && (
                    <TbCheck color="green" size={20} />
                  )}
                </div>
                <select
                  disabled={
                    !perms.testPermission(
                      [8, 9, 10, 11, 12, 13],
                      ["Create-EditAWAREFloodConnections"]
                    ) || !deviceSettings.triggeredImagingHoldOff
                  }
                  value={formatTime(deviceSettings.imgHoldEnd)}
                  onChange={(e) => {
                    setDeviceSettings({
                      ...deviceSettings,
                      imgHoldEnd: parseTime(e.target.value),
                    });
                  }}
                  style={{
                    marginLeft: 5,
                    backgroundColor: theme.primaryHighlight,
                    borderRadius: 5,
                    width: 100,
                    textAlign: "center",
                    padding: 5,
                  }}
                >
                  {Array.from({ length: 24 }, (_, i) => formatTime(i)).map(
                    (time, i) => (
                      <option key={i} value={time}>
                        {time}
                      </option>
                    )
                  )}
                </select>
                <label style={{ marginLeft: 15 }}>Hour End</label>
              </div>
            </div>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <h4 style={{ marginTop: 15, marginBottom: 10 }}>
                Action Requests
              </h4>
              <div style={{ marginLeft: 10 }}>
                <div>
                  <label>Image Request</label>
                  <input
                    disabled={
                      !perms.testPermission(
                        [8, 9, 10, 11, 12, 13],
                        ["Create-EditAWAREFloodConnections"]
                      )
                    }
                    type="checkbox"
                    style={{ marginLeft: 5, transform: "scale(1.5)" }}
                    checked={deviceSettings.imageRequest}
                    onChange={(e) => {
                      const value = e.target.checked;
                      setDeviceSettings((prevSettings) => ({
                        ...prevSettings,
                        imageRequest: value,
                      }));
                    }}
                  />
                </div>
                <div>
                  <label>GPS Sync</label>
                  <input
                    disabled={
                      !perms.testPermission(
                        [8, 9, 10, 11, 12, 13],
                        ["Create-EditAWAREFloodConnections"]
                      )
                    }
                    type="checkbox"
                    style={{
                      marginLeft: 5,
                      marginTop: 5,
                      transform: "scale(1.5)",
                    }}
                    checked={deviceSettings.gpsSync}
                    onChange={(e) => {
                      const value = e.target.checked;
                      setDeviceSettings((prevSettings) => ({
                        ...prevSettings,
                        gpsSync: value,
                      }));
                    }}
                  />
                </div>
              </div>
            </div>

            <div
              style={{
                marginTop: 20,
                padding: 10,
                borderColor: "red",
                borderWidth: 1,
                borderStyle: "solid",
              }}
            >
              <h4>Advanced Settings</h4>
              <label>Do not modify unless directed by AWARE support</label>
              <div
                style={{
                  marginTop: 15,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div>
                  <label>Destination IP Address:</label>
                  {ipParts.map((part, index) => (
                    <input
                      disabled={
                        !perms.testPermission(
                          [8, 9, 10, 11, 12, 13],
                          ["Create-EditAWAREFloodConnections"]
                        )
                      }
                      key={index}
                      type="text"
                      value={part}
                      onChange={(e) =>
                        handleIpPartChange(index, e.target.value)
                      }
                      style={{
                        marginLeft: 15,
                        backgroundColor: theme.primaryHighlight,
                        borderRadius: 5,
                        width: 50,
                        textAlign: "center",
                      }}
                    />
                  ))}
                </div>

                <div style={{ marginTop: 10 }}>
                  <label>Destination Port:</label>
                  <input
                    disabled={
                      !perms.testPermission(
                        [8, 9, 10, 11, 12, 13],
                        ["Create-EditAWAREFloodConnections"]
                      )
                    }
                    type="text"
                    value={deviceSettings.port}
                    onChange={(e) => {
                      setDeviceSettings({
                        ...deviceSettings,
                        port: e.target.value,
                      });
                    }}
                    style={{
                      marginLeft: 5,
                      backgroundColor: theme.primaryHighlight,
                      borderRadius: 5,
                      width: 50,
                      textAlign: "center",
                    }}
                  />
                </div>
                <div style={{ marginTop: 10 }}>
                  <label style={{ color: "red" }}>Reboot:</label>
                  <input
                    disabled={
                      !perms.testPermission(
                        [8, 9, 10, 11, 12, 13],
                        ["Create-EditAWAREFloodConnections"]
                      )
                    }
                    type="checkbox"
                    style={{ marginLeft: 5, transform: "scale(1.5)" }}
                    checked={deviceSettings.reboot}
                    onChange={(e) => {
                      const value = e.target.checked;
                      setDeviceSettings((prevSettings) => ({
                        ...prevSettings,
                        reboot: value,
                      }));
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {commandSent && (
            <div style={{ color: "green", margin: 10 }}>
              Command sent to server!
            </div>
          )}
          {perms.testPermission(
            [8, 9, 10, 11, 12, 13],
            ["Create-EditAWAREFloodConnections"]
          ) && (
            <button
              onClick={sendCommand}
              style={{ padding: 30, backgroundColor: theme.primaryHighlight }}
            >
              Save
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
