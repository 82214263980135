import React, { useContext, useEffect, useState } from "react";
import { BsChevronLeft } from "react-icons/bs";
import { FaCog, FaPause, FaPencilAlt, FaPlay } from "react-icons/fa";
import {
  MdOutlineSignalCellularAlt,
  MdOutlineSignalCellularAlt1Bar,
  MdOutlineSignalCellularAlt2Bar,
} from "react-icons/md";
import * as api from "apis/FusionAPI";
import PageIndex from "components/UI/PageIndex";
import { FlipIndex } from "utils/mathUtils";
import ExportModal from "./ExportModal";
import Popup from "reactjs-popup";
import { useLocation } from "react-router-dom";
import { ColorContext, DeviceContext } from "App";
import { flip } from "@turf/turf";
import { extractDateTimeFromPict } from "utils/dateUtils";
import NodeStatus from "components/NodeStatus";
import { ConnectionsContext } from "pages/System/Connections";

export let playingVideo = false;

const CurrentDevices = ({
  setShowDeviceSettings,
  selectedDevice,
  setSelectedDevice,
  showCurrentDevice,
  setShowCurrentDevice,
  nodeList,
  pageIndex,
  setPageIndex,
  handleSubmit,
  getNodeData,
  timeMultiplier,
  setTimeMultiplier,
  startTime,
  setStartTime,
  endTime,
  setEndTime,
  successMessage,
  setSuccessMessage,
  showExportModal,
  showAdvancedDate,
  setShowAdvancedDate,
  setShowExportModal,
  loadingMessage,
  setTimeSpan,
  setUsingCustomDate
}) => {
  const location = useLocation();

  const theme = useContext(ColorContext);

  const connectionsContext = useContext(ConnectionsContext);
  const deviceContext = useContext(DeviceContext);

  const [isEditing, setIsEditing] = useState(false);
  const [editAlias, setEditAlias] = useState("");

  const [hasSearchedBefore, setHasSearchedBefore] = useState(false);

  const [currImageIndex, setCurrImageIndex] = useState(0);
  const [playPauseIcon, setPlayPauseIcon] = useState(false);
  let currImageIndexLocal = 0;

  const [initialPlayState, setInitialPlayState] = useState(true);
  const [flippedDataForPageDisplay, setFlippedDataForPageDisplay] = useState(
    //change this
    nodeList ? [...nodeList] : []
  );
  const [customTimeInputs, setCustomTimeImputs] = useState({});

  const [uniqueImages, setUniqueImages] = useState({});

  useEffect(() => {}, [uniqueImages]);

  const calibrationType = [
    "Lower Pressure Sensor",
    "Lower Pressure Sensor + SDI-12 Soil Moisture",
    "Lower Pressure Sensor + Waterlog Rain Sensor",
    "Lower Pressure Sensor + Intellisense Rain Sensor",
    "Water Level Radar",
  ];

  useEffect(() => {
    setPlayPauseIcon(false);
    playingVideo = false;
    const interval = setInterval(runImage, 500);

    return () => clearInterval(interval);
  }, [showCurrentDevice, selectedDevice, pageIndex]);

  useEffect(() => {
    currImageIndexLocal = 0;
    playingVideo = false;
    setCurrImageIndex(0);
  }, [showCurrentDevice, selectedDevice]);

  useEffect(() => {
    if (
      flippedDataForPageDisplay &&
      flippedDataForPageDisplay[selectedDevice]?.data.length < 1
    )
      return;

    const index = uniqueImages[
      selectedDeviceIndexToNodeId(selectedDevice)
    ]?.picts.indexOf(
      flippedDataForPageDisplay[selectedDevice].data[pageIndex - 1].img_link_url
    );

    const flippedNewIndex = index;

    currImageIndexLocal = flippedNewIndex;
    playingVideo = false;
    setCurrImageIndex(flippedNewIndex);
  }, [pageIndex]);

  useEffect(() => {
    if (
      !nodeList ||
      !nodeList[selectedDevice] ||
      !nodeList[selectedDevice]?.data
    ) {
      setShowCurrentDevice(false);
    }
  }, [showCurrentDevice]);

  useEffect(() => {
    let d = [...nodeList];
    const temp = d
      .map((device) => {
        if (device.data) {
          let comparison;
          const dataArr = [...device.data];
          return {
            ...device,
            data: dataArr.sort((a, b) => new Date(a.ts) - new Date(b.ts)),
          };
        } else return null;
      })
      .filter((f) => f !== null);

    setFlippedDataForPageDisplay(temp);

    const uniqueImagesList = {};
    for (let i = 0; i < temp.length; i++) {
      if (!uniqueImagesList[temp[i].node_id] && temp[i].data.length > 0)
        uniqueImagesList[temp[i].node_id] = {
          IMEI: temp[i].data[0].IMEI,
          picts: [],
        };
      for (let j = 0; j < temp[i].data.length; j++) {
        if (
          !uniqueImagesList[temp[i].node_id].picts.includes(
            temp[i].data[j].img_link_url
          ) &&
          temp[i].data[j].img_link_url !== null &&
          temp[i].data[j].img_link_url !== undefined
        ) {
          uniqueImagesList[temp[i].node_id].picts.push(
            temp[i].data[j].img_link_url
          );
        } else {
        }
      }
    }

    setUniqueImages(uniqueImagesList);
  }, [nodeList]);

  useEffect(() => {
    if (hasSearchedBefore) setSuccessMessage("Done!");

    setTimeout(() => {
      setSuccessMessage("");
      setHasSearchedBefore(true);
    }, 5000);
  }, [nodeList]);

  const signalStrength = (rssi) => {
    rssi = -parseInt(rssi);
    if (rssi <= -50)
      return (
        <div>
          <MdOutlineSignalCellularAlt /> (High)
        </div>
      );
    else if (rssi <= -71)
      return (
        <div>
          <MdOutlineSignalCellularAlt2Bar /> (Medium)
        </div>
      );
    else if (rssi <= -100)
      return (
        <div>
          <MdOutlineSignalCellularAlt1Bar /> (Low)
        </div>
      );
    else return "No Signal";
  };

  useEffect(() => {
    setCurrImageIndex(currImageIndex);
  }, [currImageIndex]);

  const playPauseButton = () => {
    playingVideo = !playingVideo;
    setPlayPauseIcon(playingVideo);
  };

  const selectedDeviceIndexToNodeId = (selectedDeviceIndex) => {
    const id = flippedDataForPageDisplay[selectedDeviceIndex].node_id;
    return id;
  };

  const runImage = () => {
    if (playingVideo) {
      const max_frames =
        uniqueImages[selectedDeviceIndexToNodeId(selectedDevice)]?.picts
          .length || 0;

      currImageIndexLocal += 1;
      currImageIndexLocal %= max_frames;
      let newIndex = currImageIndexLocal;

      setCurrImageIndex(newIndex);
    }
  };

  const handleSaveAlias = async () => {
    const response = await api.editAwareAlias(
      flippedDataForPageDisplay[selectedDevice].node_id,

      editAlias
    );

    setIsEditing(false);
    handleSubmit();
    // getNodeData();
  };

  const formatDateTimeLocal = (date) => {
    const d = new Date(date);

    const pad = (num) => String(num).padStart(2, "0");

    const formattedDate = `${d.getFullYear()}-${pad(d.getMonth() + 1)}-${pad(
      d.getDate()
    )}T${pad(d.getHours())}:${pad(d.getMinutes())}`;

    return formattedDate;
  };

  let currentSystemType = null;

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <div
        style={{
          display: "flex",
          // justifyContent: "space-between",
          alignContent: "center",
          flexDirection: "column",
          gap: 10,
          height: "100%",
        }}
      >
        {connectionsContext.canViewDeviceType({ system_type: "AWARE" }) && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: theme.primaryShadow,
              borderRadius: 10,
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignContent: "center",
              }}
            >
              <select
                style={{
                  background: theme.primaryHighlight,
                  padding: 10,
                  borderRadius: 10,
                  margin: 10,
                  width: "100%",
                }}
                value={timeMultiplier}
                onChange={(e) => {
                  if (e.target.value === "-1") {
                    return;
                  }
                  if (e.target.value === "0") {
                    setTimeMultiplier(parseInt(e.target.value));
                    setShowAdvancedDate(true);
                  } else {
                    setTimeMultiplier(parseInt(e.target.value));
                    setShowAdvancedDate(false);
                    setStartTime(Date.now() - parseInt(e.target.value) * 60000);
                    setEndTime(Date.now());
                    setTimeSpan(parseInt(e.target.value));
                  }
                }}
              >
                <option value="-1">Time Period</option>
                <option value="0">Custom</option>
                <option value="1">1 Minute</option>
                <option value="2">2 Minutes</option>
                <option value="5">5 Minutes</option>
                <option value="10">10 Minutes</option>
                <option value="15">15 Minutes</option>
                <option value="30">30 Minutes</option>
                <option value="60">1 Hour</option>
                <option value="120">2 Hours</option>
                <option value="180">3 Hours</option>
                <option value="240">4 Hours</option>
                <option value="300">5 Hours</option>
                <option value="600">10 Hours</option>
                <option value="720">12 Hours</option>
                <option value="1440">1 Day</option>
                <option value="10080">7 Days</option>
                <option value="43200">30 Days</option>
              </select>
              <select
                style={{
                  background: theme.primaryHighlight,
                  padding: 10,
                  borderRadius: 10,
                  margin: 10,
                  width: "100%",
                }}
              >
                <option value="0">Data Aggregation</option>
              </select>
            </div>
            {showExportModal && (
              <Popup
                open={showExportModal}
                closeOnDocumentClick={false}
                onClose={() => setShowExportModal(false)}
              >
                <ExportModal
                  startTime={startTime}
                  setStartTime={setStartTime}
                  endTime={endTime}
                  setEndTime={setEndTime}
                  formatDateTimeLocal={formatDateTimeLocal}
                  data={nodeList}
                  selectedDevice={selectedDevice}
                  setShowExportModal={setShowExportModal}
                />
              </Popup>
            )}
            {showAdvancedDate && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: 10,
                }}
              >
                Time Period
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  Date From
                  <input
                    style={{
                      background: theme.primaryHighlight,
                      padding: 10,
                      borderRadius: 10,
                      marginBottom: 10,
                    }}
                    type="datetime-local"
                    value={
                      customTimeInputs.start || formatDateTimeLocal(Date.now())
                    }
                    onChange={(e) => {
                      setCustomTimeImputs({
                        ...customTimeInputs,
                        start: e.target.value,
                      });

                      setStartTime(new Date(e.target.value).getTime());
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  Date To
                  <input
                    style={{
                      background: theme.primaryHighlight,
                      padding: 10,
                      borderRadius: 10,
                    }}
                    type="datetime-local"
                    value={
                      customTimeInputs.end || formatDateTimeLocal(Date.now())
                    }
                    onChange={(e) => {
                      setCustomTimeImputs({
                        ...customTimeInputs,
                        end: e.target.value,
                      });

                      setEndTime(new Date(e.target.value).getTime());
                    }}
                  />
                </div>
              </div>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <button
                style={{
                  background: theme.primaryHighlight,
                  padding: 10,
                  borderRadius: 10,
                  margin: 10,
                  width: "max-content",
                }}
                onClick={() => {
                  setShowExportModal(true);
                }}
              >
                Export
              </button>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: 10,
                }}
              >
                <div
                  style={{
                    width: "100%",
                    color: "green",
                    // marginRight: 20,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {!deviceContext.fetchingData && successMessage}
                </div>
                <button
                  onClick={() => {
                    setTimeMultiplier(1440);
                    setShowAdvancedDate(false);
                    setUsingCustomDate(false);
                    handleSubmit();
                  }}
                >
                  Cancel
                </button>
                <button
                  style={{
                    background: loadingMessage
                      ? "gray"
                      : theme.primaryHighlight,
                    padding: 10,
                    borderRadius: 10,
                    margin: 10,
                    width: "max-content",
                  }}
                  onClick={() => {
                    if (!loadingMessage) {
                      setUsingCustomDate(true);
                      handleSubmit();
                    }
                  }}
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        )}
        <div
          style={{
            backgroundColor: theme.primaryShadow,
            borderRadius: 10,
            overflow: "auto",
          }}
        >
          {showCurrentDevice &&
            !deviceContext.fetchingData &&
            nodeList[selectedDevice].system_type === "AWARE" && (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: 10,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignContent: "center",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        cursor: "pointer",
                        alignItems: "center",
                        display: "flex",
                        width: 100,
                      }}
                      onClick={() => {
                        setIsEditing(false);
                        setShowCurrentDevice(false);
                      }}
                    >
                      <BsChevronLeft style={{ top: 20 }} size={20} />
                      <div style={{ marginLeft: 10 }}>Back</div>
                    </div>
                    <div style={{ fontSize: 20, fontWeight: "bold" }}>
                      Device Data:
                    </div>
                    <button
                      style={{
                        width: 100,
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowDeviceSettings(true);
                      }}
                    >
                      <FaCog />
                    </button>
                  </div>
                  {flippedDataForPageDisplay[selectedDevice] &&
                    flippedDataForPageDisplay[selectedDevice].data[
                      pageIndex - 1
                    ]?.ts &&
                    flippedDataForPageDisplay[selectedDevice]?.data.length !==
                      0 && (
                      <DataPair
                        label="Time Stamp"
                        value={new Date(
                          flippedDataForPageDisplay[selectedDevice].data[
                            pageIndex - 1
                          ].ts
                        ).toLocaleString()}
                      />
                    )}
                </div>
                {flippedDataForPageDisplay[selectedDevice]?.data.length === 0 &&
                  "No data available for this device and selected time period."}
                {flippedDataForPageDisplay[selectedDevice].data[
                  pageIndex - 1
                ] &&
                  flippedDataForPageDisplay[selectedDevice] &&
                  flippedDataForPageDisplay[selectedDevice]?.data.length >
                    0 && (
                    <div>
                      <div
                        style={{
                          overflow: "auto",
                          height:
                            location.pathname === "/system/integrations"
                              ? window.innerHeight - 475
                              : window.innerHeight - 440,
                        }}
                      >
                        <div>
                          <DataPair
                            label="Entity Name"
                            value={
                              flippedDataForPageDisplay[selectedDevice].data[0]
                                .node_name
                            }
                          />
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <DataPair
                              label="Device Alias"
                              value={
                                isEditing ? (
                                  <>
                                    <input
                                      type="text"
                                      value={editAlias}
                                      onChange={async (e) => {
                                        setEditAlias(e.target.value);
                                      }}
                                      style={{
                                        borderRadius: 5,
                                        padding: 5,
                                        border: "1px solid white",
                                        backgroundColor: theme.base,
                                        color: theme.fontColor,
                                      }}
                                    />
                                    <button
                                      onClick={handleSaveAlias}
                                      style={{ marginLeft: 10 }}
                                    >
                                      Save
                                    </button>
                                  </>
                                ) : (
                                  flippedDataForPageDisplay[selectedDevice]
                                    .alias
                                )
                              }
                            />
                            {!isEditing && (
                              <button
                                onClick={() => {
                                  setIsEditing(true);
                                  setEditAlias(
                                    flippedDataForPageDisplay[selectedDevice]
                                      .alias
                                  );
                                }}
                                style={{ marginLeft: 10 }}
                              >
                                <FaPencilAlt />
                              </button>
                            )}
                          </div>
                          <DataPair
                            label="Signal Strength (Bars)"
                            value={signalStrength(
                              flippedDataForPageDisplay[selectedDevice].data[
                                pageIndex - 1
                              ].rssi
                            )}
                          />
                          <DataPair
                            label="Battery"
                            value={
                              flippedDataForPageDisplay[selectedDevice].data[
                                pageIndex - 1
                              ].battery
                            }
                            units="v"
                          />
                          <RateIndicator
                            label="Rise Rate Indicator"
                            value={
                              flippedDataForPageDisplay[selectedDevice].data[
                                pageIndex - 1
                              ].ffi1
                            }
                          />
                          <RateIndicator
                            label="Drop Rate Indicator"
                            value={
                              flippedDataForPageDisplay[selectedDevice].data[
                                pageIndex - 1
                              ].drInd
                            }
                          />
                          <RateIndicator
                            label="Depth Trigger Indicator"
                            value={
                              flippedDataForPageDisplay[selectedDevice].data[
                                pageIndex - 1
                              ].depthInd
                            }
                          />
                          <DataPair
                            label="Cell Signal Strength"
                            value={
                              "-" +
                              flippedDataForPageDisplay[selectedDevice].data[
                                pageIndex - 1
                              ].rssi
                            }
                            units={"dB"}
                          />
                          <DataPair
                            label="IMEI #"
                            value={
                              flippedDataForPageDisplay[selectedDevice].data[
                                pageIndex - 1
                              ].IMEI
                            }
                          />
                          <DataPair
                            label="Node ID"
                            value={
                              flippedDataForPageDisplay[selectedDevice].node_id
                            }
                          />
                          <DataPair
                            label="GPS Location"
                            value={`${flippedDataForPageDisplay[selectedDevice].location.lat}, ${flippedDataForPageDisplay[selectedDevice].location.lng}`}
                          />
                          <DataPair
                            label="Elevation"
                            value={
                              flippedDataForPageDisplay[selectedDevice].elev +
                              " ft/" +
                              (
                                flippedDataForPageDisplay[selectedDevice].elev *
                                0.3048
                              ).toFixed(3) +
                              " m"
                            }
                          />
                          <DataPair
                            label="Barometric Pressure"
                            value={
                              flippedDataForPageDisplay[selectedDevice].data[
                                pageIndex - 1
                              ].baro +
                              " mBar/" +
                              (
                                flippedDataForPageDisplay[selectedDevice].data[
                                  pageIndex - 1
                                ].baro * 0.02952998
                              ).toFixed(3) +
                              " inHg"
                            }
                          />
                          {/* HERE */}
                          <DataPair
                            label="Water Level Adjustment"
                            value={
                              flippedDataForPageDisplay[selectedDevice]
                                .device_settings.water_level_adj +
                              " in/" +
                              (
                                flippedDataForPageDisplay[selectedDevice]
                                  .device_settings.water_level_adj / 12
                              ).toFixed(3) +
                              " ft"
                            }
                          />
                          <DataPair
                            label="Depth Measurement"
                            value={
                              flippedDataForPageDisplay[selectedDevice].data[
                                pageIndex - 1
                              ].depth1 +
                              " in/" +
                              (
                                flippedDataForPageDisplay[selectedDevice].data[
                                  pageIndex - 1
                                ].depth1 / 12
                              ).toFixed(3) +
                              " ft"
                            }
                          />
                          <DataPair
                            label="Actual Depth"
                            value={
                              flippedDataForPageDisplay[selectedDevice].data[
                                pageIndex - 1
                              ].sensordepths +
                              " in/" +
                              (
                                flippedDataForPageDisplay[selectedDevice].data[
                                  pageIndex - 1
                                ].sensordepths / 12
                              ).toFixed(3) +
                              " ft"
                            }
                          />
                          <DataPair
                            label="Water Temperature"
                            value={
                              flippedDataForPageDisplay[selectedDevice].data[
                                pageIndex - 1
                              ].h2oTemp +
                              "°C/" +
                              (
                                (flippedDataForPageDisplay[selectedDevice].data[
                                  pageIndex - 1
                                ].h2oTemp *
                                  9) /
                                  5 +
                                32
                              ).toFixed(2) +
                              " °F"
                            }
                          />
                          <DataPair
                            label="Device Type"
                            value={
                              calibrationType[
                                flippedDataForPageDisplay[selectedDevice].data[
                                  pageIndex - 1
                                ].calType
                              ]
                            }
                          />
                          {flippedDataForPageDisplay[selectedDevice].data[
                            pageIndex - 1
                          ].soilTempSDI &&
                            flippedDataForPageDisplay[selectedDevice].data[
                              pageIndex - 1
                            ].soilSDI && (
                              <DataPair
                                label="External Device Data"
                                value={
                                  flippedDataForPageDisplay[selectedDevice]
                                    .data[pageIndex - 1].soilSDI +
                                  ", " +
                                  flippedDataForPageDisplay[selectedDevice]
                                    .data[pageIndex - 1].soilTempSDI
                                }
                              />
                            )}
                          <DataPair
                            label="Gateway Type"
                            value={
                              deviceContext.devices[selectedDevice].gatewayType
                                ? "FirstNet Cellular"
                                : "Satellite"
                            }
                          />
                          {uniqueImages[
                            selectedDeviceIndexToNodeId(selectedDevice)
                          ]?.picts &&
                          uniqueImages[
                            selectedDeviceIndexToNodeId(selectedDevice)
                          ]?.picts[
                            currImageIndex %
                              uniqueImages[
                                selectedDeviceIndexToNodeId(selectedDevice)
                              ]?.picts.length
                          ] ? (
                            <div style={{ padding: 10, display: "flex" }}>
                              <div
                                style={{
                                  padding: 10,
                                  display: "flex",
                                  alignItems: "flex-start",
                                  position: "relative", // Add this line
                                  width: 320,
                                  height: 240,
                                }}
                              >
                                {uniqueImages[
                                  selectedDeviceIndexToNodeId(selectedDevice)
                                ]?.picts && (
                                  <img
                                    src={
                                      uniqueImages[
                                        selectedDeviceIndexToNodeId(
                                          selectedDevice
                                        )
                                      ]?.picts[
                                        currImageIndex %
                                          uniqueImages[
                                            selectedDeviceIndexToNodeId(
                                              selectedDevice
                                            )
                                          ]?.picts.length || 0
                                      ] || "failure"
                                    }
                                    alt="Device Video"
                                    style={{ width: 320, height: 240 }} // Ensure the image fits the container
                                  />
                                )}
                                {nodeList[selectedDevice].data.length > 1 && (
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: initialPlayState ? "50%" : "15%",
                                      right: initialPlayState ? "" : "0%",
                                      left: initialPlayState ? "50%" : "",
                                      transform: "translate(-50%, -50%)",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      flexDirection: "column",
                                      width: initialPlayState ? 300 : "",
                                      height: initialPlayState ? 220 : "",
                                      backgroundColor: initialPlayState
                                        ? "rgba(0, 0, 0, 0.3)"
                                        : "", // Optional: Add a semi-transparent background for better visibility
                                      padding: 10, // Optional: Add padding for better appearance
                                      borderRadius: 5, // Optional: Add border-radius for rounded corners
                                    }}
                                  >
                                    <button
                                      onClick={() => {
                                        playPauseButton();
                                        setInitialPlayState(false);
                                      }}
                                    >
                                      {playPauseIcon ? <FaPause /> : <FaPlay />}
                                    </button>
                                  </div>
                                )}
                                <div
                                  style={{
                                    position: "absolute",
                                    bottom: 15,
                                    left: 20,
                                    width: 320,
                                    height: 20,
                                    textShadow: "2px 2px 2px black",
                                  }}
                                >
                                  {/* PROBLEM HERE! */}
                                  {Object.keys(uniqueImages).length > 0 &&
                                    uniqueImages[
                                      selectedDeviceIndexToNodeId(
                                        selectedDevice
                                      )
                                    ]?.picts[currImageIndex] &&
                                    extractDateTimeFromPict(
                                      uniqueImages[
                                        selectedDeviceIndexToNodeId(
                                          selectedDevice
                                        )
                                      ]?.picts[currImageIndex]
                                    )}{" "}
                                  ({currImageIndex + 1}/
                                  {uniqueImages[
                                    selectedDeviceIndexToNodeId(selectedDevice)
                                  ]?.picts.length || 0}
                                  )
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div
                              style={{
                                padding: 10,
                              }}
                            >
                              <div
                                style={{
                                  padding: 10,
                                  display: "flex",
                                  // alignItems: "flex-start",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  position: "relative", // Add this line
                                  width: 300,
                                  height: 240,
                                  backgroundColor: "rgba(0, 0, 0, 0.3)",
                                }}
                              >
                                No images available for this device.
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <PageIndex
                        pageIndex={FlipIndex(
                          pageIndex,
                          nodeList[selectedDevice].data.length
                        )}
                        setPageIndex={(index) => {
                          const num = FlipIndex(
                            index,
                            nodeList[selectedDevice].data.length
                          );

                          setPageIndex(num);
                        }}
                        maxPages={nodeList[selectedDevice].data.length}
                      />
                    </div>
                  )}
              </>
            )}
          {deviceContext.fetchingData && (
            <div
              style={{
                padding: 10,
              }}
            >
              Loading...
            </div>
          )}
          <div style={{ display: "flex" }}></div>
          {!showCurrentDevice && !deviceContext.fetchingData && (
            <div
              style={{
                height: "100%",
                overflow: "auto",
              }}
            >
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "6fr 2fr 2fr 1fr",
                  color: theme.fontColor,
                  fontWeight: "bold",
                  padding: 10,
                  marginBottom: 7,
                }}
              >
                <div>Name</div>
                <div>ID</div>
                <div>Status</div>
              </div>
              {flippedDataForPageDisplay.length > 0 && (
                <div>
                  {nodeList
                    .toSorted((a, b) => {
                      const systemTypeComparison = a.system_type.localeCompare(
                        b.system_type
                      );
                      if (systemTypeComparison !== 0) {
                        return systemTypeComparison;
                      }
                      const deviceTypeComparison = a.device_type.localeCompare(
                        b.device_type
                      );
                      if (deviceTypeComparison !== 0) {
                        return deviceTypeComparison;
                      }
                      return parseInt(a.node_id, 10) - parseInt(b.node_id, 10);
                    })
                    .map((device, index) => {
                      let device_type = device.device_type;

                      if (device_type.includes("_")) {
                        let str = "";
                        const subStrs = device_type.split("_");
                        for (let i = 0; i < subStrs.length; i++) {
                          if (i == 0) str += subStrs[i];
                          else str += " (" + subStrs[i] + ")";
                        }

                        device_type = str;
                      }

                      const showHeader =
                        device.device_type !== currentSystemType;
                      currentSystemType = device.device_type;
                      if (connectionsContext.canViewDeviceType(device))
                        return (
                          <React.Fragment key={index}>
                            {showHeader && (
                              <div
                                style={{
                                  fontWeight: "bold",
                                  marginTop: "10px",
                                  textAlign: "center",
                                }}
                              >
                                {device.system_type} - {device_type}
                              </div>
                            )}
                            <div
                              style={{
                                display: "grid",
                                gridTemplateColumns: "6fr 2fr 2fr 1fr",
                                borderTop: "1px solid grey",
                                padding: 10,
                                cursor: "pointer",
                                alignItems: "center",
                              }}
                              onClick={() => {
                                setSelectedDevice(index);
                                if (nodeList[index].system_type === "AWARE") {
                                  setPageIndex(
                                    flippedDataForPageDisplay[index].data.length
                                  );
                                  setShowCurrentDevice(true);
                                }
                                if (
                                  nodeList[index].system_type ===
                                  "RescAlert Devices"
                                ) {
                                  setShowDeviceSettings(true);
                                }
                              }}
                            >
                              <p
                                style={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {(device.device_type !== "Manual Road Closure"
                                  ? device.static_id + " "
                                  : "") + device.alias}
                              </p>
                              <p>{device.node_id}</p>

                              <NodeStatus device={device} />
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setSelectedDevice(index);
                                  setShowDeviceSettings(true);
                                }}
                              >
                                <FaCog />
                              </button>
                            </div>
                          </React.Fragment>
                        );
                    })}
                </div>
              )}
              {flippedDataForPageDisplay.length === 0 &&
                "No data available for this time period."}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const DataPair = ({ label, value, units = null }) => {
  if (value !== null && value !== undefined)
    return (
      <div style={{ padding: 10, display: "flex" }}>
        <div style={{ fontWeight: "bold", marginRight: 10 }}>{label}:</div>
        {value} {units}
      </div>
    );

  return null;
};

const RateIndicator = ({ label, value, units = null }) => {
  let displayValue = value;
  let style = {};

  if (label === "Rise Rate Indicator") {
    if (value === 0) {
      displayValue = "No Flooding Alert";
    } else if (value === 1) {
      displayValue = "Flooding Alert!";
      style = { color: "red" };
    }
  }

  if (label === "Drop Rate Indicator") {
    if (value === 0) {
      displayValue = "No Drop Rate Alert";
    } else if (value === 1) {
      displayValue = "Drop Rate Alert!";
      style = { color: "red" };
    }
  }

  if (label === "Depth Trigger Indicator") {
    if (value === 0) {
      displayValue = "No Depth Trigger Alert";
    } else if (value === 1) {
      displayValue = "Depth Trigger Alert!";
      style = { color: "red" };
    }
  }

  if (value !== null && value !== undefined) {
    return (
      <div style={{ padding: 10, display: "flex" }}>
        <div style={{ fontWeight: "bold", marginRight: 10 }}>{label}:</div>
        <div style={style}>
          {displayValue} {units}
        </div>
      </div>
    );
  }

  return null;
};

export default CurrentDevices;
