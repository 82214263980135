import React, {
  useEffect,
  useState,
  createContext,
  useContext,
  useRef,
} from "react";
import "./mobile.css";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  redirect,
} from "react-router-dom";
// import { setKey } from "react-geocode";
import * as api from "./apis/FusionAPI";

import Sidebar from "./components/Sidebar";
import Header from "./components/Header";

import Dashboard from "./pages/Dashboard";
import CreateAlert from "./pages/CreateAlert";
import ViewAlerts from "./pages/ViewAlerts";
import Signin from "./pages/Signin";
import Signup from "./pages/Signup";

import ResponderDashboard from "./pages/Responder/ResponderDashboard";
import FullMap from "./pages/Responder/FullMap";
import Incidents from "./pages/Responder/Incidents";
import Employees from "./pages/Responder/Employees";
import Apparatus from "./pages/Responder/Apparatus";
import DispatcherChat from "./pages/Responder/DispatcherChat";

import PublicUserDashboard from "./pages/Weather/PublicUserDashboard";
import Alerts from "./pages/Weather/Alerts";
import Instances from "./pages/Weather/Instances";
import WeatherForecast from "./pages/Weather/WeatherForecast";
import Maps from "./pages/Weather/Maps";
import StormCenter from "./pages/Weather/StormCenter";
import MarineForecast from "./pages/Weather/MarineForecast";
import SpecialReports from "./pages/Weather/SpecialReports";
import Charts from "./pages/Weather/Charts";

import SystemDashboard from "./pages/System/SystemDashboard";
import Connections from "./pages/System/Connections";
import ManageSocials from "./pages/System/ManageSocials";
import Help from "./pages/System/Help";
import Settings from "./pages/System/Settings";

import ActivateAccount from "./pages/ActivateAccount";
import CreateUser from "./pages/Admin/CreateUser";

import RoleManager from "./pages/Admin/RoleManager";
import CreateRole from "./pages/Admin/CreateRole";
import AccessibilitySettings from "./pages/AccessibilitySettings";

import EditUser from "./pages/Admin/EditUser";
import EditRole from "./pages/Admin/EditRole";

import GetWidgetData from "./data/widgetData";
import GlobalActions from "pages/System/GlobalActions";
import Cookies from "js-cookie";
import AlertCreator from "components/AlertCreator/AlertCreator";
import { GetRouteData } from "data/routeData";
import ResetPassword from "pages/ResetPassword";
import { ForgotPassword } from "pages/ForgotPassword";
import UserMenu from "pages/UserMenu";
import AlarmListener from "components/AlarmListener";
import { PrivacyPopup } from "pages/PrivacyPopup";
import { usePerms } from "hooks/usePerms";
import { Page404 } from "pages/404";
import TermsAndConditions from "pages/TermsAndConditions";
import PolicyAndCookies from "pages/PolicyAndCookies";

import { ConfigProvider } from "antd";
import ThemeSelectorTool from "components/ThemeSelectorTool";
import { clearCookies } from "utils/cookieUtils";

import {
  useAccount,
  useIsAuthenticated,
  MsalProvider,
  useMsal,
} from "@azure/msal-react";
import {
  Configuration,
  PublicClientApplication,
  LogLevel,
} from "@azure/msal-browser";
import Redirect from "pages/Redirect";
import MobileSignIn from "mobile/pages/MobileSignIn";
import MobileDashboard from "mobile/pages/MobileDashboard";
import MobileHeader from "mobile/pages/MobileHeader";
import MobileLoadingMessage from "mobile/pages/MobileLoadingMessage";
import MobilePopup from "mobile/pages/MobilePopup";
import { playingVideo } from "components/Connections/CurrentDevices";

let href = window.location.origin;
const getRedirectUri = () => {
  // console.log("window.location.origin", window.location.origin);
  const environment = api._Environment();
  let redirectUri = "";

  if (href.includes("localhost:3000")) {
    redirectUri = "http://localhost:3000"; //Backend Fusion )shared)
  } else if (href.includes("192")) {
    redirectUri = window.location.origin;
  } else if (href.includes("localhost:3001")) {
    redirectUri = "http://localhost:3001"; //Webpack (Wes' Port)
  } else if (href.includes("localhost:3002")) {
    redirectUri = "http://localhost:3002"; //MikeTec's port
  } else if (href.includes("localhost:3003")) {
    redirectUri = "http://localhost:3003"; //Brenton's port
  } else if (href.includes("localhost:3004")) {
    redirectUri = "http://localhost:3004"; //Noah's port
  } else if (href.includes("localhost:3005")) {
    redirectUri = "http://localhost:3005"; //Joey's port
  } else if (href.includes("devdemo")) {
    redirectUri = "https://devdemo.overwatch.ai";
  } else if (href.includes("staging")) {
    redirectUri = "https://stagingdemo.overwatch.ai";
  } else if (href.includes("pittsburgh")) {
    redirectUri = "https://pittsburghpa.overwatch.ai";
  } else if (href.includes("comal")) {
    redirectUri = "https://comaltx.overwatch.ai";
  } else if (href.includes("demo")) {
    redirectUri = "https://demo.overwatch.ai";
  } else {
    redirectUri = "https://demo.overwatch.ai";
  }

  return redirectUri;
};
const configuration = {
  auth: {
    clientId: "fc3e525d-dc3e-4aaf-a892-ab5d48134bfe",
    authority: "https://login.microsoftonline.com/common",
    redirectUri: getRedirectUri(),
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: true,
    },
  },

  //redirectUri: "localhost:3000"
  // redirectUri: "your-redirect-uri" redirectUri: The URI where the response from Azure AD is sent back to your application.  (This needs to be stored/fetched from backend)

  //const options = {
  //identityMetadata: `https://login.microsoftonline.com/common/v2.0/.well-known/openid-configuration`,
  //validateIssuer: false, // Accept tokens from any issuer
};

const pca = new PublicClientApplication(configuration);

export const SidebarContext = createContext(true);
export const ThemesListContext = createContext([]);
export const MicrosoftContext = createContext();

const baseSize = 1000;
export const LayoutSizeContext = createContext({
  width: baseSize,
  height: window.innerHeight - 137,
  widgetWidth: baseSize / 3,
  doubleWidgetWidth: (baseSize / 3) * 2 + 9,
  widgetHeight: baseSize / 3,
  doubleWidgetHeight: baseSize / 3 + 100,
  scaleMult: 1,
  baseFontSize: 14,
});

//how many colors do we need?
//are there any colors that serve a specific purpose (ie. main background color (that 'dark-grey' color behind all of the pages/widgets))?
export const UniqueWidgetRefreshContext = createContext({
  base: 0,
  primary: 0,
  secondary: 0,
  tertiary: 0,
  fontColor: "white",
});

export const SetUniqueWidgetRefreshContext = createContext(() => {});

export const ColorContext = createContext({});
export const SetColorContext = createContext(() => {});
export const DeviceContext = createContext({});
export const PermissionContext = createContext({});
export const UserAgentContext = createContext({});

function App() {
  const [userAgent, setUserAgent] = useState({
    device: navigator.userAgent,
    mobile: /Mobile|Android|iPhone|iPad/i.test(navigator.userAgent),
    test: (types) => {
      return types.some((type) => navigator.userAgent.includes(type));
    },
    isIPhone: () => userAgent.test(["iPhone"]),
    isIos: () => userAgent.test(["iPhone", "iPad"]),
    isAndroid: () => userAgent.test(["Android"]),
    isMobile: () => userAgent.mobile,
  });

  //universal color context
  const themes = [
    {
      name: "Default",
      value: {
        //main colors
        background: "#0b1826", //darkblue bg, main web page backgrond
        base: "#222a2d", //component background
        primaryShadow: "#1e374f", //dark blue, header, sidebar
        primary: "#325277",
        primaryHighlight: "#436c9a",
        fontColor: "#FFF",
      },
    },
    {
      name: "Greyscale",
      value: {
        background: "#000000", //darkblue bg, main web page backgrond
        base: "#111111", //component background
        primaryShadow: "#222222", //dark blue, header, sidebar
        primary: "#333333",
        primaryHighlight: "#444444",
        fontColor: "#FFF",
      },
    },
    {
      name: "High Contrast",
      value: {
        background: "#000", //darkblue bg, main web page backgrond
        base: "#FFFFFF", //component background
        primaryShadow: "#00FF00", //dark blue, header, sidebar
        primary: "#FFFF00",
        primaryHighlight: "#00FFFF",
        fontColor: "#000",
      },
    },
    {
      name: "Light Mode",
      value: {
        background: "#f5f5f5", //darkblue bg, main web page backgrond
        base: "#FFFFFF", //component background
        primaryShadow: "#e0e0e0", //dark blue, header, sidebar
        primary: "#d3d3d3",
        primaryHighlight: "#c0c0c0",
        fontColor: "#000",
      },
    },
    {
      name: "Scarlet",
      value: {
        background: "#07080e",
        base: "#07080e",
        primaryShadow: "#15040b",
        primary: "#350d1d",
        primaryHighlight: "#801530",
        fontColor: "#ff0055",
      },
    },
  ];

  const [selectedTheme, setSelectedTheme] = useState(themes[0]);

  const { instance, accounts, inProgress } = useMsal();

  const [colors, setColors] = useState({
    ...themes[0].value,
  });

  //change colors in react and css
  useEffect(() => {
    for (const key in selectedTheme.value) {
      document.documentElement.style.setProperty(
        `--${key}`,
        selectedTheme.value[key]
      );
    }
    setColors(selectedTheme.value);
  }, [selectedTheme]);

  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [showFull, setShowFull] = useState(
    !process.env.NODE_ENV === "development" || true
  );

  const [updateSidebar, setUpdateSidebar] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [routeName, setRouteName] = useState({
    name: "dashboard",
    displayName: "Home Dashboard",
  });

  const [isAuthenticated, setIsAuthenticated] = useState(null);

  const [accessLevel, setAccessLevel] = useState(-1);

  const [search, setSearch] = useState("");

  const [widgetRefresh, setWidgetRefresh] = useState("");
  const [cookiesAgreed, setCookiesAgreed] = useState(false);
  const [privacyAgreed, setPrivacyAgreed] = useState(false);
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [newTermsFullyAgreed, setNewTermsFullyAgreed] = useState(false);
  const [hasSignedIn, setHasSignedIn] = useState(false);

  const [pagesLoaded, setPagesLoaded] = useState(false);

  const [termsFullyViewed, setTermsFullyViewed] = useState(false);
  const [mobileLoadingSignin, setMobileLoadingSignin] = useState(false);

  const [termsData, setTermsData] = useState({
    version: "",
    page: "",
  });

  const [policyData, setPolicyData] = useState({
    version: "",
    page: "",
  });

  const [showSmallMenu, setShowSmallMenu] = useState(true);

  useEffect(() => {
    authenticate();
  }, []);

  useEffect(() => {
    const fetchTermsAndConditions = async () => {
      try {
        const termsData = await api.getTermsAndConditions();
        if (termsData.status !== 200) {
          alert("Unable to connect to the server. Please try again later.");

          return;
        }
        setTermsData(termsData.data);
      } catch (error) {
        console.error("Error fetching terms and conditions:", error);
      }
    };

    const fetchPolicyAndCookies = async () => {
      try {
        const policyData = await api.getPolicyAndCookies();
        if (policyData?.status !== 200) {
          alert("Unable to connect to the server. Please try again later.");

          return;
        }
        setPolicyData(policyData.data);
      } catch (error) {
        alert("Unable to connect to the server. Please try again later.");
        console.error("Error fetching policy and cookies:", error);
      }
    };

    fetchTermsAndConditions();
    fetchPolicyAndCookies();
  }, []);

  const perms = usePerms();
  useEffect(() => {
    if (!isAuthenticated) {
      Cookies.remove("perms");
      Cookies.remove("accessLevel");
      return;
    }
    try {
      perms.get();
    } catch (e) {}
  }, [isAuthenticated]);

  //DEVICE DATA
  const [devices, setFDevices] = useState([]);
  let _staticDevices = [];
  const setDevices = (newState) => {
    console.trace("Set Devices Run:", newState);
    setFDevices(newState);
    _staticDevices = newState;
  };
  const [deviceTypes, setDeviceTypes] = useState([]);
  const [integrations, setIntegrations] = useState([]);
  const [deviceGroups, setDeviceGroups] = useState([]);
  const [licenseCounts, setLicenseCounts] = useState({});
  const [fetchingDevices, setFetchingDevices] = useState(false);
  const [fetchingDeviceData, setFetchingDeviceData] = useState(true);
  const [hasFetchedPerms, setHasFetchedPerms] = useState(false);

  let fetchedDevices = [];

  const MAX_DEVICE_QUERY_LENGTH = 1;

  const getDeviceData = async (startTime, endTime, initList) => {
    let deviceList = initList ? initList : devices;

    const list = deviceList.map((device) => parseInt(device.id));

    let devicesResult = await api.getNodeDataByTimestamps(
      startTime ? startTime : new Date().getTime() - 24 * 60 * 60 * 1000,
      endTime ? endTime : new Date().getTime(),
      list
    );

    if (devicesResult.status !== 200) {
      setFetchingDeviceData(false);
      alert(
        "Unable to fetch full device data.  Please refresh the page to try again."
      );
      return;
    }

    devicesResult = devicesResult.data.result;

    devicesResult = deviceList.map((device) => {
      const data = devicesResult.filter((f) => {
        return f[0].node_id == device.node_id;
      })[0];

      return {
        ...device,
        data: data ? data : device.data,
      };
    });

    // console.log("devicesResult", devicesResult);

    setDevices(devicesResult);
  };

  const getDeviceTypes = async () => {
    const deviceTypeRes = await api.getDeviceTypes();
    if (deviceTypeRes.status === 200) setDeviceTypes(deviceTypeRes.data.result);
  };

  let runs = 0;
  let deviceEventSource;

  const getDevices = async (options = { ignoreFetching: false }) => {
    console.trace("Get devices called");

    if (deviceEventSource) return;

    if (runs > 0) return;

    if (options.ignoreFetching) {
      //
    } else {
      setFetchingDevices(true);
      setFetchingDeviceData(true);
    }

    const deviceTypeRes = await api.getDeviceTypes();
    if (deviceTypeRes.status === 200) setDeviceTypes(deviceTypeRes.data.result);

    const res = await api.fusionGetIntegrations(); //get me system with a list of devices on that system
    if (res.status === 200) setIntegrations(res.data.result);

    const res2 = await api.getDeviceGroups(); //get me groups with a list of devices in them
    if (res2.status === 200) setDeviceGroups(res2.data.result);

    const res3 = await api.getConnectedDevices();
    if (res3.status === 200) {
      // setDevices(res3.data.result); //re-enable this if you are going to decouple the getDevices and getDeviceDataByTimestamp functionality
      setLicenseCounts(res3.data.subCounts[0]);
    }

    setFetchingDevices(false);
    setTimeout(async () => {
      try {
        if (options.ignoreFetching) {
          //
        } else {
          setFetchingDeviceData(true);
        }

        await getDeviceData(null, null, res3.data.result);
        setFetchingDeviceData(false);
      } catch (e) {
        alert(
          "Unable to fetch device data.  Please refresh the page to try again."
        );
        setFetchingDeviceData(false);
      }
    }, 2000);

    if (deviceEventSource === null || deviceEventSource === undefined) {
      console.log("Initializing event source", deviceEventSource);
      deviceEventSource = api.getConnectedDevicesEventSource();

      deviceEventSource.onopen = (e) => {
        console.log("Connected to GetConnectedDevices_Event:", e);
      };

      deviceEventSource.onmessage = (e) => {
        try {
          let eventData = JSON.parse(e.data);
          console.log("Data in message:", eventData);

          if (!Array.isArray(eventData)) {
            eventData = [eventData];
          }

          console.log("Trying to make updated devices:", _staticDevices);
          const updatedDevices = _staticDevices.map((device) => {
            console.log("Trying device:", device);

            let result = device;
            const match = eventData.find(
              (eventDevice) => eventDevice.id === device.id
            );

            if (match) {
              console.log("Match found", device, match);

              //replace device details
              console.log("Match update:", {
                ...device,
                ...match,
                data: [...match.data, ...device.data],
              });

              result = {
                ...device,
                ...match,
                data: [...match.data, ...device.data],
              };
            }

            return result;
          });

          _staticDevices = updatedDevices;
          setDevices(updatedDevices);
        } catch (e) {
          console.error("Error on message in DeviceEventSource:", e);
        }
      };

      deviceEventSource.onerror = (e) => {
        console.error("Error from server:", e);
      };

      deviceEventSource.onClose = (e) => {
        deviceEventSource = null;
      };
    }

    runs++;
  };

  console.log("Devices list:", devices);

  const getDevice = async (deviceList) => {
    //call new endpoint
    const list = deviceList.map((device) => parseInt(device.id));
    const res = await api.getConnectedDevice(list);

    if (res.status === 200) {
      let newDevices = res.data.result;

      let newDevicesArr = devices.map((device) => {
        let i = newDevices.findIndex((v) => v.id === device.id);

        if (i > -1) return { ...device, ...newDevices[i] };
        else return device;
      });

      setDevices(newDevicesArr);
    }
  };

  // PERMISSION CONTEXT
  const [permFetching, setPermFetching] = useState(false);
  const [permData, setPermData] = useState({});

  const fetchPerms = async () => {
    return false;
  };

  const authenticate = async () => {
    if (
      Cookies.get("accessToken") !== undefined &&
      Cookies.get("accessToken") !== null
    ) {
      try {
        const res = await api.getAccessLevel();
        if (res.status === 200) {
          perms.setAccessLevel(res.data.result.group_id);
          perms.setMaintenanceLevel(res.data.result.maintenanceOperatorLevel);
          setAccessLevel(res.data.result.group_id);
          setIsAuthenticated(true);
        }
      } catch (e) {
        Cookies.remove("accessToken");
        Cookies.remove("refreshToken");
        Cookies.remove("email");
        setIsAuthenticated(false);
      }
    } else {
      setIsAuthenticated(false);
    }
  };

  const updateAuthenticated = (value) => {
    setIsAuthenticated(value);

    if (!value) return;

    api
      .getAccessLevel()
      .then((accessLevelRes) => {
        if (accessLevelRes.status === 200) {
          perms.setAccessLevel(accessLevelRes.data.result.group_id);
          perms.setMaintenanceLevel(
            accessLevelRes.data.result.maintenanceOperatorLevel
          );
          setAccessLevel(accessLevelRes.data.result.group_id);
          //get permissions
          perms.get();
        }
      })
      .catch((e) => console.error(e));
  };

  const [showThemeSelector, setShowThemeSelector] = useState(false);

  const divStyle = {
    color: colors.fontColor,
    width: "100%",
  };
  const [routes, setRoutes] = useState([]);

  useEffect(() => {
    const cookie = perms.get();
    if (!cookie || !isAuthenticated) {
      return;
    }
    let allowed = [];

    const appdata = [...GetRouteData(perms, widgetRefresh)];

    appdata.forEach((item) => {
      if (item.permission()) {
        item.options.forEach((option) => {
          //set enabled by perm
          if (option.permission()) {
            allowed.push(option);
          }
        });
      }
    });
    setRoutes(allowed);
    setPagesLoaded(true);
  }, [perms.sData, updateSidebar]);

  useEffect(() => {
    perms.reset();
    if (isAuthenticated) setShowFull(false);
  }, [isAuthenticated]);

  const initialWidth = Math.max(
    window.innerWidth - (sidebarOpen ? 295 : 100),
    1000
  );

  useEffect(() => {
    setTimeout(() => {
      if (isAuthenticated) {
        const interval = setInterval(() => {
          if (perms.get()) {
            setHasFetchedPerms(true);
            clearInterval(interval);
          } else {
          }
        }, 100);
      }
    }, 1000);
  }, [isAuthenticated]);

  const [layoutObject, setLayoutObject] = useState({
    width: initialWidth,
    height: window.innerHeight - 137,
    widgetWidth: initialWidth / 3,
    doubleWidgetWidth: (initialWidth / 3) * 2 + 9,
    widgetHeight: initialWidth / 3,
    doubleWidgetHeight: initialWidth / 3 + 100,
    scaleMult: 1,
    baseFontSize: 14,
  });

  const [microsoftContextValue, setMicrosoftContextValue] = useState({
    hasClientId: false,
  });

  const [uwfValues, setUwfValues] = useState({
    base: 0,
  });

  useEffect(() => {
    const handleResize = () => {
      const newSize = Math.max(
        window.innerWidth - (sidebarOpen ? 295 : 100),
        1000
      );

      const newSizeHeight = window.innerHeight - 107;

      setLayoutObject({
        width: newSize * 0.9875,
        height: newSizeHeight,
        widgetWidth: newSize / 3,
        doubleWidgetWidth: (newSize / 3) * 2 + 9,
        widgetHeight: newSize / 3,
        doubleWidgetHeight: (newSize / 3) * 2 + 9,
        scaleMult: newSize / baseSize,
        baseFontSize: Math.min(
          Math.max(((16 * newSize) / baseSize) * 0.75, 14),
          30
        ),
      });
    };
    window.addEventListener("resize", handleResize);

    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [sidebarOpen, window.innerWidth, window.innerHeight]);

  const helpdeskAddedRef = useRef(false);

  return (
    <ContextProvider
      colors={colors}
      sidebarOpen={sidebarOpen}
      layoutObject={layoutObject}
      microsoftContextValue={microsoftContextValue}
      setMicrosoftContextValue={setMicrosoftContextValue}
      uwfValues={uwfValues}
      setUwfValues={setUwfValues}
      themes={themes}
      setColors={setColors}
      deviceData={{
        integrations,
        setIntegrations,
        devices,
        setDevices,
        deviceGroups,
        setDeviceGroups,
        getDeviceData,
        getDevices,
        getDevice,
        getDeviceTypes,
        licenseCounts,
        fetching: fetchingDevices,
        setFetching: setFetchingDevices,
        fetchingData: fetchingDeviceData,
        setFetchingData: setFetchingDeviceData,
        deviceTypes,
      }}
      userAgent={userAgent}
    >
      {process.env.NODE_ENV === "development" && showThemeSelector && (
        <ThemeSelectorTool
          setSelectedTheme={setSelectedTheme}
          setShowThemeSelector={setShowThemeSelector}
        />
      )}
      {userAgent.mobile ? (
        <Router>
          <div
            className='mobile'
            style={{
              color: colors.fontColor,
              background: colors.background,
            }}
          >
            <div className='content'>
              {mobileLoadingSignin && (
                <MobilePopup
                  title=''
                  canClose={false}
                  content={
                    <div>
                      <MobileLoadingMessage message='Logging in...' />
                    </div>
                  }
                />
              )}
              {process.env.NODE_ENV === "development" &&
                userAgent.isIPhone() &&
                window.location.origin.includes("localhost") &&
                false && (
                  <div
                    style={{
                      position: "fixed",
                      bottom: 0,
                      left: 0,
                      right: 0,
                      height: "10vh",
                      // background: "white",
                      color: "white",
                      border: "4px solid white",
                      zIndex: 1000,
                    }}
                  >
                    IOS OFFSET - ONLY VISIBLE ON LOCALHOST
                  </div>
                )}
              {showSmallMenu && (
                <PrivacyPopup
                  mobile
                  setShowFull={setShowFull}
                  showFull={showFull}
                  isAuthenticated={isAuthenticated}
                  acceptAction={() => {
                    setCookiesAgreed(true);
                    setPrivacyAgreed(true);
                  }}
                  declineAction={() => {
                    if (isAuthenticated) api.logout();
                    setHasFetchedPerms(false);
                    setCookiesAgreed(false);
                    setPrivacyAgreed(false);
                    setNewTermsFullyAgreed(false);
                    setTermsFullyViewed(false);
                    setHasSignedIn(false);
                    setIsAuthenticated(false);
                    clearCookies();
                    // setMicrosoftButtonPressed(false);
                  }}
                />
              )}
              {isAuthenticated && (
                <MobileHeader
                  setHasSignedIn={setHasSignedIn}
                  routeName={routeName}
                  isAuthenticated={isAuthenticated}
                  widgetRefresh={widgetRefresh}
                  setWidgetRefresh={setWidgetRefresh}
                  setIsAuthenticated={(value) => updateAuthenticated(value)}
                  setShowFull={setShowFull}
                  setNewTermsFullyAgreed={setNewTermsFullyAgreed}
                  setTermsFullyViewed={setTermsFullyViewed}
                  setHasFetchedPerms={setHasFetchedPerms}
                  setShowThemeSelector={setShowThemeSelector}
                  showThemeSelector={showThemeSelector}
                />
              )}
              <Routes>
                {isAuthenticated === false && (
                  <Route path='*' element={<Redirect route={"/mobile/"} />} />
                )}
                {routes.length > 0 && pagesLoaded && (
                  <>
                    <Route path='*' element={<Redirect route={"/mobile/"} />} />
                  </>
                )}
                <Route
                  path='/mobile/terms'
                  element={
                    <TermsAndConditions
                      data={termsData}
                      setShowFull={setShowFull}
                      setShowSmallMenu={setShowSmallMenu}
                      mobile
                    />
                  }
                />
                <Route
                  path='/mobile/policy'
                  element={
                    <PolicyAndCookies
                      data={policyData}
                      setShowFull={setShowFull}
                      setShowSmallMenu={setShowSmallMenu}
                      mobile
                    />
                  }
                />
                <Route
                  path='/mobile/forgotpassword'
                  element={
                    <ForgotPassword
                      setShowSmallMenu={setShowSmallMenu}
                      mobile
                    />
                  }
                />
                <Route
                  path='/mobile/resetPassword'
                  element={
                    <ResetPassword
                      setCookiesAgreed={setCookiesAgreed}
                      setPrivacyAgreed={setPrivacyAgreed}
                      setShowSmallMenu={setShowSmallMenu}
                      mobile
                    />
                  }
                />
                <Route
                  path='/forgotpassword'
                  element={
                    <ForgotPassword
                      setShowSmallMenu={setShowSmallMenu}
                      mobile
                    />
                  }
                />
                <Route
                  path='/resetPassword'
                  element={
                    <ResetPassword
                      setCookiesAgreed={setCookiesAgreed}
                      setPrivacyAgreed={setPrivacyAgreed}
                      setShowSmallMenu={setShowSmallMenu}
                      mobile
                    />
                  }
                />
                {isAuthenticated === false && (
                  <Route
                    path='/mobile'
                    element={
                      <MobileSignIn
                        setShowFull={setShowFull}
                        hasSignedIn={hasSignedIn}
                        setHasSignedIn={setHasSignedIn}
                        setAuthenticated={(value) => updateAuthenticated(value)}
                        widgetRefresh={widgetRefresh}
                        setCookiesAgreed={setCookiesAgreed}
                        setPrivacyAgreed={setPrivacyAgreed}
                        setTermsAgreed={setTermsAgreed}
                        setNewTermsFullyAgreed={setNewTermsFullyAgreed}
                        newTermsFullyAgreed={newTermsFullyAgreed}
                        cookiesAgreed={cookiesAgreed}
                        privacyAgreed={privacyAgreed}
                        termsAgreed={termsAgreed}
                        termsVersion={termsData?.version}
                        policyVersion={policyData?.version}
                        termsDate={termsData?.date}
                        terms={termsData?.page}
                        termsFullyViewed={termsFullyViewed}
                        setTermsFullyViewed={setTermsFullyViewed}
                        isAuthenticated={isAuthenticated}
                        setIsAuthenticated={setIsAuthenticated}
                        setMobileLoadingSignin={setMobileLoadingSignin}
                        mobileLoadingSignin={mobileLoadingSignin}

                        // microsoftButtonPressed={microsoftButtonPressed}
                        // setMicrosoftButtonPressed={setMicrosoftButtonPressed}
                      />
                    }
                  />
                )}
                {isAuthenticated &&
                // perms == valid perms
                hasFetchedPerms ? (
                  routes.filter((route) => route.mobilePage).length > 0 ? (
                    <Route
                      path='/mobile'
                      element={
                        <MobileDashboard
                          routes={routes.filter((f) => f.mobilePage)}
                        />
                      }
                    />
                  ) : (
                    <Route
                      path='/mobile'
                      element={
                        <div
                          style={{
                            padding: 20,
                          }}
                        >
                          No valid routes found, please try logging out and back
                          in.
                        </div>
                      }
                    />
                  )
                ) : (
                  <Route
                    path='/mobile'
                    element={
                      <div>
                        <MobileLoadingMessage message='Loading dashboard...' />
                      </div>
                    }
                  />
                )}
                {routes.length > 0 &&
                  routes
                    .filter((f) => f.mobilePage)
                    .map((route, index) => {
                      return (
                        <Route
                          key={index}
                          path={"/mobile" + route.href}
                          element={route.mobilePage}
                        />
                      );
                    })}
              </Routes>
            </div>
          </div>
        </Router>
      ) : (
        <Router>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              backgroundColor: colors.background,
              backgroundSize: "cover",
              backgroundRepeat: "repeat",
              width: "100vw",
              height: "100vh",
              flex: 1,
              flexGrow: 1,
            }}
          >
            {
              <div
                className='screenTooSmall'
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100vh",
                  width: "100vw",
                  color: colors.fontColor,
                  backgroundColor: colors.background,
                  zIndex: 1300,
                }}
              >
                <div
                  style={{
                    textAlign: "center",
                  }}
                >
                  <h1>Device Size Unsupported</h1>
                  <p>
                    Please increase the size of your browser window to at least
                    1240px wide.
                  </p>
                  <h2
                    style={{
                      marginTop: 20,
                      textDecoration: "underline",
                    }}
                    onClick={() => {
                      setUserAgent({
                        ...userAgent,
                        mobile: true,
                      });
                    }}
                  >
                    Switch to mobile
                  </h2>
                </div>
              </div>
            }
            {perms.testPermission([8, 9, 10, 11, 12, 13], ["PendingAlarms"]) &&
              isAuthenticated && (
                <AlarmListener isAuthenticated={isAuthenticated} />
              )}
            {isAuthenticated && (
              <Sidebar
                onOpenChanged={setSidebarOpen}
                routeName={routeName}
                accessLevel={accessLevel}
                isAuthenticated={isAuthenticated}
                updateSidebar={updateSidebar}
                definedWidgetData={GetWidgetData(
                  widgetRefresh,
                  setWidgetRefresh,
                  sidebarOpen
                )}
              />
            )}

            <div
              style={{
                width: "100%",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              {showSmallMenu && (
                <PrivacyPopup
                  setShowFull={setShowFull}
                  showFull={showFull}
                  isAuthenticated={isAuthenticated}
                  acceptAction={() => {
                    setCookiesAgreed(true);
                    setPrivacyAgreed(true);
                  }}
                  declineAction={() => {
                    if (isAuthenticated) api.logout();
                    setHasFetchedPerms(false);
                    setCookiesAgreed(false);
                    setPrivacyAgreed(false);
                    setNewTermsFullyAgreed(false);
                    setTermsFullyViewed(false);
                    setHasSignedIn(false);
                    setIsAuthenticated(false);
                    clearCookies();
                    // setMicrosoftButtonPressed(false);
                  }}
                />
              )}
              {isAuthenticated && (
                <Header
                  setHasSignedIn={setHasSignedIn}
                  routeName={routeName}
                  isAuthenticated={isAuthenticated}
                  widgetRefresh={widgetRefresh}
                  setWidgetRefresh={setWidgetRefresh}
                  setIsAuthenticated={(value) => updateAuthenticated(value)}
                  setShowFull={setShowFull}
                  setNewTermsFullyAgreed={setNewTermsFullyAgreed}
                  setTermsFullyViewed={setTermsFullyViewed}
                  setHasFetchedPerms={setHasFetchedPerms}
                />
              )}
              <div
                style={{
                  width: "calc(100% - 30px)",
                  height: "calc(100% - 137px)",
                  color: colors.fontColor,
                  margin: 15,
                  display: "flex",
                }}
              >
                <Routes>
                  {/* Redirect to login */}
                  {isAuthenticated === false && (
                    <>
                      <Route path='*' element={<Redirect route={"/"} />} />
                      <Route
                        path='/'
                        element={
                          <Signin
                            setShowFull={setShowFull}
                            hasSignedIn={hasSignedIn}
                            setHasSignedIn={setHasSignedIn}
                            setAuthenticated={(value) =>
                              updateAuthenticated(value)
                            }
                            widgetRefresh={widgetRefresh}
                            setCookiesAgreed={setCookiesAgreed}
                            setPrivacyAgreed={setPrivacyAgreed}
                            setTermsAgreed={setTermsAgreed}
                            setNewTermsFullyAgreed={setNewTermsFullyAgreed}
                            newTermsFullyAgreed={newTermsFullyAgreed}
                            cookiesAgreed={cookiesAgreed}
                            privacyAgreed={privacyAgreed}
                            termsAgreed={termsAgreed}
                            termsVersion={termsData?.version}
                            policyVersion={policyData?.version}
                            termsDate={termsData?.date}
                            terms={termsData?.page}
                            termsFullyViewed={termsFullyViewed}
                            setTermsFullyViewed={setTermsFullyViewed}
                            isAuthenticated={isAuthenticated}
                            setIsAuthenticated={setIsAuthenticated}
                            // microsoftButtonPressed={microsoftButtonPressed}
                            // setMicrosoftButtonPressed={
                            //   setMicrosoftButtonPressed
                            // }
                          />
                        }
                      />
                      <Route
                        path='/activate'
                        element={
                          <PageContentWrapper>
                            <ActivateAccount
                              setCookiesAgreed={setCookiesAgreed}
                              setPrivacyAgreed={setPrivacyAgreed}
                            />
                          </PageContentWrapper>
                        }
                      />
                      <Route
                        path='/resetPassword'
                        element={
                          <PageContentWrapper>
                            <ResetPassword
                              setCookiesAgreed={setCookiesAgreed}
                              setPrivacyAgreed={setPrivacyAgreed}
                              setShowSmallMenu={setShowSmallMenu}
                            />
                          </PageContentWrapper>
                        }
                      />
                      <Route
                        path='/forgotPassword'
                        element={
                          <PageContentWrapper>
                            <ForgotPassword
                              setShowSmallMenu={setShowSmallMenu}
                            />
                          </PageContentWrapper>
                        }
                      />
                      <Route
                        path='/terms'
                        element={
                          <PageContentWrapper>
                            <TermsAndConditions
                              data={termsData}
                              setShowFull={setShowFull}
                              setShowSmallMenu={setShowSmallMenu}
                            />
                          </PageContentWrapper>
                        }
                      />
                      <Route
                        path='/policy'
                        element={
                          <PageContentWrapper>
                            <PolicyAndCookies
                              data={policyData}
                              setShowFull={setShowFull}
                              setShowSmallMenu={setShowSmallMenu}
                            />
                          </PageContentWrapper>
                        }
                      />
                    </>
                  )}
                  {isAuthenticated && (
                    <>
                      <Route
                        path='/'
                        element={<Redirect route={"/dashboard"} />}
                      />
                      <Route
                        path='/activate'
                        element={
                          <PageContentWrapper>
                            <ActivateAccount
                              setCookiesAgreed={setCookiesAgreed}
                              setPrivacyAgreed={setPrivacyAgreed}
                            />
                          </PageContentWrapper>
                        }
                      />
                      <Route
                        path='/accessibility'
                        element={
                          <PageContentWrapper>
                            <AccessibilitySettings />
                          </PageContentWrapper>
                        }
                      />
                      <Route
                        path='/register'
                        element={
                          <PageContentWrapper>
                            <Signup />
                          </PageContentWrapper>
                        }
                      />
                      <Route
                        path='/dashboard'
                        element={
                          <div
                            style={{
                              ...divStyle,
                              overflow: "auto",
                            }}
                            className='customScrollbar-X  customScrollbar-Y'
                          >
                            <Dashboard
                              dimensions={dimensions}
                              sidebarOpen={sidebarOpen}
                              search={search}
                              definedWidgetData={GetWidgetData(
                                widgetRefresh,
                                setWidgetRefresh,
                                sidebarOpen
                              )}
                              accessLevel={accessLevel}
                            />
                          </div>
                        }
                      />
                      <Route
                        path='/terms'
                        element={
                          <PageContentWrapper>
                            <TermsAndConditions
                              data={termsData}
                              setShowFull={setShowFull}
                              setShowSmallMenu={setShowSmallMenu}
                            />
                          </PageContentWrapper>
                        }
                      />
                      <Route
                        path='/policy'
                        element={
                          <PageContentWrapper>
                            <PolicyAndCookies
                              setShowFull={setShowFull}
                              setShowSmallMenu={setShowSmallMenu}
                              data={policyData}
                            />
                          </PageContentWrapper>
                        }
                      />
                      {routes.length > 0 && pagesLoaded && (
                        <>
                          <Route path='*' element={<Page404 />} />
                        </>
                      )}
                      {routes.length > 0 &&
                        routes
                          .filter((f) => f.page)
                          .map((route, index) => (
                            <Route
                              key={index}
                              path={route.href}
                              element={
                                <PageContentWrapper>
                                  {route.page}
                                </PageContentWrapper>
                              }
                            />
                          ))}
                    </>
                  )}
                </Routes>
              </div>
            </div>
          </div>
        </Router>
      )}
    </ContextProvider>
  );
}

export default App;

const PageContentWrapper = ({ children }) => {
  const theme = useContext(ColorContext);
  return (
    <div
      className='page-content customScrollbar-X customScrollbar-Y'
      style={{ height: "100%", background: theme.base }}
    >
      {children}
    </div>
  );
};

const ContextProvider = ({
  children,
  colors,
  sidebarOpen,
  layoutObject,
  microsoftContextValue,
  setMicrosoftContextValue,
  uwfValues,
  setUwfValues,
  themes,
  setColors,
  deviceData,
  userAgent,
}) => {
  return (
    <MsalProvider instance={pca}>
      <ConfigProvider theme={{ token: { colorPrimary: colors.primaryShadow } }}>
        <MicrosoftContext.Provider
          value={{
            get: () => microsoftContextValue,
            set: setMicrosoftContextValue,
          }}
        >
          <UserAgentContext.Provider value={userAgent}>
            <SidebarContext.Provider value={sidebarOpen}>
              <LayoutSizeContext.Provider value={layoutObject}>
                <UniqueWidgetRefreshContext.Provider value={uwfValues}>
                  <SetUniqueWidgetRefreshContext.Provider value={setUwfValues}>
                    <ColorContext.Provider value={colors}>
                      <SetColorContext.Provider value={setColors}>
                        <ThemesListContext.Provider value={themes}>
                          <DeviceContext.Provider value={deviceData}>
                            {children}
                          </DeviceContext.Provider>
                        </ThemesListContext.Provider>
                      </SetColorContext.Provider>
                    </ColorContext.Provider>
                  </SetUniqueWidgetRefreshContext.Provider>
                </UniqueWidgetRefreshContext.Provider>
              </LayoutSizeContext.Provider>
            </SidebarContext.Provider>
          </UserAgentContext.Provider>
        </MicrosoftContext.Provider>
      </ConfigProvider>
    </MsalProvider>
  );
};
