import * as d3 from "d3";
import Cookies from "js-cookie";
import { flattenPolygon } from "./geometryUtils";
import Massive_Unionized_MultiPolygons from "data/Massive_Unionized_MultiPolygons.json";

export const alertColorScale = d3
  .scaleLinear()
  .domain([0, 66, 100])
  .range(["#CD2C2E", "#EE8918", "#34A100"]);

export function getSeverityLevel(value) {
  if (value >= 0 && value < 20) {
    return "Extreme";
  } else if (value >= 20 && value < 40) {
    return "Severe";
  } else if (value >= 40 && value < 80) {
    return "Moderate";
  } else if (value >= 80 && value <= 100) {
    return "Minor";
  } else {
    return "Invalid value";
  }
}

export function CalculateCentroid(polygon) {
  let totalX = 0;
  let totalY = 0;

  for (let i = 0; i < polygon.length; i++) {
    totalX += polygon[i][0];
    totalY += polygon[i][1];
  }

  const centroidX = totalX / polygon.length;
  const centroidY = totalY / polygon.length;

  return [centroidX, centroidY];
}

export function GetDistanceToUser(inPoint) {
  //get user point
  const userPoint = [Cookies.get("latitude") || 100000, Cookies.get("longitude") || 100000]

  //convert to radians
  userPoint[0] = userPoint[0] * Math.PI / 180;
  userPoint[1] = userPoint[1] * Math.PI / 180;
  const point = [inPoint[0] * Math.PI / 180, inPoint[1] * Math.PI / 180];

  //get distance between two points
  const distance = (Math.acos(Math.sin(userPoint[0]) * Math.sin(point[0]) + Math.cos(userPoint[0]) * Math.cos(point[0]) * Math.cos(point[1] - userPoint[1])) * 6371) * 0.6213711922;

  return distance
}

export async function ProcessAlert(alert) {
  try {
        if (alert.polygon) {
          const singlePolygon = JSON.parse(alert.polygon);
          const centroid = CalculateCentroid(singlePolygon);
          const distance = GetDistanceToUser(centroid);
  
          return {...alert, processedPolygon: [singlePolygon], processedCenter: centroid, distanceToUser: distance};
        } else if (alert.zoneCodes) {
          const zoneCodes = [...new Set(alert.zoneCodes)];
          let polygons = [];
          let centroids = [];
  
          zoneCodes.forEach((zoneCode) => {
            try {
              const responses = Massive_Unionized_MultiPolygons[zoneCode];
  
              responses.forEach((response) => {
                const innerPolygon = flattenPolygon(response).map((coord) => [
                  coord[1],
                  coord[0],
                ]);
  
                polygons.push(innerPolygon);
  
                const flippedForCentroid = innerPolygon.map((coord) => [
                  coord[1],
                  coord[0],
                ]);
  
                let avgLon = 0;
                let avgLat = 0;
                flippedForCentroid.forEach((coord) => {
                  avgLon += coord[0];
                  avgLat += coord[1];
                });
                avgLon /= flippedForCentroid.length;
                avgLat /= flippedForCentroid.length;
  
                const centroid = [avgLon, avgLat];
                centroids.push(centroid);
              });//
            } catch (e) {}
          });
          
          const averageLongitude = d3.mean(centroids, (d) => d[0]);
          
          const averageLatitude = d3.mean(centroids, (d) => d[1]);
  
          const averageCentroid = [averageLatitude, averageLongitude];
  
          const distance = GetDistanceToUser(averageCentroid);
  
          return {...alert, processedPolygon: polygons.length > 0 ? polygons : [[0, 0]], processedCenter: averageCentroid, distanceToUser: distance};
        }
      } catch (e) {
        throw e;
      }
}

export function GetPageFromList(list, pageLimit, pageNumber){
  return list.filter((item, index) => {
    console.log("Page:", pageLimit, pageNumber);

    //page check
    const pageStart = pageLimit * (pageNumber - 1);
    const pageEnd = pageStart + pageLimit;
  
    return index >= pageStart && index < pageEnd;
  })
}